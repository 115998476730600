<template>
  <div>
    <div class="main-Pc-wholeImg">
      <div class="nav-box">
        <div class="nav">
          <img class="Pc-back" :src="Pc_back" alt="" @click="pageHandle('/home')">
          <img class="Pc-select" :src="Pc_select" alt="" @click="select()">
          <ul class="nav-language" v-if="flag">
            <div class="r"></div>
            <li><a href="/tcapp-letsgo-jp">日本語</a></li>
            <li><a href="/tcapp-letsgo-zh">简体中文</a></li>
            <li><a href="/tcapp-letsgo-tw">繁体中文</a></li>
            <li><a href="/tcapp-letsgo-en">English</a></li>
            <li><a href="/tcapp-letsgo-ko">한국어</a></li>
          </ul>
        </div>
      </div>
      <img class="Pc-wholeImg" :src="banner" width="100%" lazy @click="background()">
      <div class="Pc-link-box">
        <a class="Pc-apple-link" href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" target="_blank" rel="noopener noreferrer">
          <img :src="MB_apple" alt="">
        </a>
        <!-- <a class="Pc-google-link" href=" https://play.google.com/store/apps/details?id=com.tripellet.app" target="_blank" rel="noopener noreferrer">
          <img :src="MB_google" alt="">
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { reloadMessage } from "@/lang"
import aboutsPageTopMb from '@/components/aboutsPage-top-mb'
export default {
  data() {
    return {
      banner: require("@/assets/images/customer/APP_HP_SC_mb.jpg"),
      MB_apple: require("@/assets/images/customer/apple.png"),
      MB_google: require("@/assets/images/customer/GOOGLE.png"),
      Pc_back: require("@/assets/images/customer/MB-home.png"),
      Pc_select: require("@/assets/images/customer/MB_line_1.png"),
      flag: false,
    }
  },
  components: {
    aboutsPageTopMb
  },
    mounted(){
    // 进入页面回到顶部
    this.$router.afterEach((to,from,next)=>{
      window.scrollTo(0,0)
    })
  },
  computed: {
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } })
      } else {
        this.$router.push(path)
      }
    },
    changLan(val) {
      // 设置语言
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
      reloadMessage();
      this.$router.go(0);
    },
    select() {
      this.flag = !this.flag
    },
    background() {
      this.flag = false
    }
  }
}
</script>

<style lang='scss' scoped>
body {
  width: 100%;
  height: 100%;
}

.app-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  border: 0;
}

.main-Pc-wholeImg {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.Pc-back {
  width: 30px;
  position: absolute;
  left: 6%;
  top: -4px;
}
.Pc-link-box {
  position: absolute;
  bottom: 4.3rem;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.Pc-apple-link img {
  width: 100px;
  margin-right: 10px;
}
.Pc-google-link img {
  width: 110px;
}

.Pc-wholeImg {
  width: 100%;
  height: 100%;
}
.Pc-selectBox {
  float: right;
}
.Pc-select {
  height: 22px;
  float: right;
  cursor: pointer;
}
.nav-box {
  height: 44px;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  position: absolute;
}
.nav {
  width: 100%;
  display: block;
  position: absolute;
  top: 13px;
  left: -3%;
}
.nav-language {
  position: absolute;
  right: 0;
  top: 25px;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 #0000001a;
}
.nav-language li {
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
  border-bottom: 1px solid #0000001a;
}
.nav-language li a {
  color: #606266;
}
.nav-language li a:hover {
  color: #66b1ff;
}
.nav-language li:hover {
  background-color: #ecf5ff;
  color: #66b1ff;
}
.r{
    width: 10px;
    height: 10px;
    border-right: #ebeef5 solid 1px;
    border-bottom: #ebeef5 solid 1px;
    transform: translateY(50%) rotate(225deg);
    background-color: #fff;
    position: absolute;
    top: -11px;
    left: 40px;
}
</style>